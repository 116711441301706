import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: unknown): void {
    console.log(error)

    if (error instanceof Error) {
      const _error = error;
      const chunkFailedMessage = /Loading chunk \d+ failed/;
      if (chunkFailedMessage.test(_error.message)) {
        window.location.reload();
      } else {
        if (_error && (_error as unknown as { ngOriginalError: Error }).ngOriginalError) {
          Sentry.captureException((_error as unknown as { ngOriginalError: Error }).ngOriginalError);
        }
      }
    } else {
      Sentry.captureException(error);
    }
  }
}
