import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private doc: Document, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private getFavicon() {
    return this.doc.querySelector('link[rel*=\'icon\']');
  }

  public setFavicon(iconURL: string) {
    const link = this.getFavicon() || this.renderer.createElement('link');
    this.appendLinkTag(link, iconURL);
  }

  private appendLinkTag(link: any, iconURL: string): void {
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = iconURL;
    const head = this.doc.getElementsByTagName('head')[0];
    this.renderer.appendChild(head, link);
  }
}
