import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ApiModule, BASE_PATH, Configuration } from 'frontend-flow-api';
import { ApiModule as DataApi, BASE_PATH as DataPAth, Configuration as DataApiConf } from 'frontend-data-api';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslationProvider } from '../locale/TranslationProvider';
import localecs from '@angular/common/locales/cs';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorInterceptorInterceptor } from './http-error-interceptor.interceptor';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { JwtInterceptorInterceptor } from './jwt-interceptor.interceptor';
import { SentryErrorHandler } from './sentry.error.handler';

registerLocaleData(localecs);

const config = () => {
  return new Configuration({
    credentials: {
    }
  });

};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    ApiModule.forRoot(config),
    DataApi.forRoot(() => new DataApiConf({credentials: {}})),
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: BASE_PATH,
      useValue: environment.baseUrl
    },
    {
      provide: DataPAth,
      useValue: environment.baseUrl
    },
    { provide: LOCALE_ID, useValue: 'cs' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorInterceptor, multi: true },
    TranslationProvider,
    SentryErrorHandler,
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorInterceptor,
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}
