import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { ConfigurationProperties } from './app/services/configuration.service';

let env = 'DEV';
Object.entries(environment.environment).forEach((val) => {
  if (location.origin === val[1]) {
    env = val[0];
  }
});

Sentry.init({
  dsn: localStorage.getItem(ConfigurationProperties.DSN) || environment.dsn,
  environment: localStorage.getItem(ConfigurationProperties.ENVIRONMENT) || env,
  release: '17.1.0',
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: JSON.parse(localStorage.getItem(ConfigurationProperties.TRACING_ORIGINS) || '[]'),
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
