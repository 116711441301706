export const environment = {
  production: true,
  baseUrl: '/api',
  cdn: 'https://cdn.finbricks.com',
  dsn: 'https://7332cb38cd74471e92d1ff47d917a1b4@o557728.ingest.sentry.io/5693957',
  environment: {
    local: 'http://localhost:4200',
    SANDBOX: 'https://service.sandbox.finbricks.com',
    PROD: 'https://service.finbricks.com'
  }
};
