import { Injectable } from '@angular/core';
import { FrontendDataService } from 'frontend-data-api/api/frontendData.service';
import { environment } from 'src/environments/environment';

export enum ConfigurationProperties {
  DSN = 'dsn',
  CDN_BASE_URL = 'cdnBaseUrl',
  TRACING_ORIGINS = 'tracingOrigins',
  ENVIRONMENT = 'environement',

}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private dataService: FrontendDataService) {
    this.init();
  }

  cdnBaseUrl(): string {
    return localStorage.getItem(ConfigurationProperties.CDN_BASE_URL) || environment.cdn;
  }

  private init(): void {
    this.dataService.configurationFrontendGet().subscribe((data) => {
      localStorage.setItem(ConfigurationProperties.DSN, data.dsn || '');
      localStorage.setItem(ConfigurationProperties.CDN_BASE_URL, data.cdnBaseUrl || '');
      localStorage.setItem(ConfigurationProperties.TRACING_ORIGINS, JSON.stringify(data.tracingOrigins) || '[]');
      localStorage.setItem(ConfigurationProperties.ENVIRONMENT, data.environement || '');
    });
  }
}
