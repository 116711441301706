import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LoggingService } from './services/logging.service';
import { FlowStorageService } from './abstracts/flow-storage.service';

@Injectable()
export class HttpErrorInterceptorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private loggingService: LoggingService, private activatedRoute: ActivatedRoute) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isConf = request.url === '/api/frontend/data/configuration';
    if (!this.router.url.includes('page/start') && !isConf) {
      const flowId = this.activatedRoute.snapshot.queryParamMap.get('flowId') || '';
      if (!FlowStorageService.checkActualSession(flowId)) {
        this.router.navigate(['/page/error'], {
          queryParams: {
            code: 309,
            final: true
          }
        });
        return EMPTY;
      }
    }
    return next.handle(request)
      .pipe(catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status >= 500) {
              this.loggingService.warn('Error response detected', {
                message: err.message,
                status: err.status.toString(),
                statusText: err.statusText
              });
              this.router.navigate(['/page/error'], {
                queryParams: {
                  internal: true
                }
              });
            }

            this.router.navigate(['/page/error'], {
              queryParams: {
                code: err.error.code,
                message: err.message,
                status: err.status.toString(),
                statusText: err.statusText
              }
            });
          }
          return throwError(err);

        }
      ));
  }
}
