import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LoggingService, LogLevels } from './services/logging.service';
import { environment } from '../environments/environment';
import { IconService } from './services/icon.service';
import { ConfigurationService } from './services/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription$: Subscription = new Subscription();

  constructor(configuration: ConfigurationService, private readonly iconService: IconService, private loggingService: LoggingService) {
    this.iconService.setFavicon(configuration.cdnBaseUrl() + '/logos/finbricks/favicon/favicon.svg');
  }

  ngOnInit(): void {
    this.subscription$.add(
      this.loggingService.logItems$().pipe(
        filter((item) => {

          if (item.level === LogLevels.error || item.level === LogLevels.fatal) {
            return true;
          }
          return !environment.production;
        })
      ).subscribe((value) => {
        console.log(value.level, value.message, value.info);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
