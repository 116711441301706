import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const jwt = sessionStorage.getItem('jwt');
    if (!jwt) {
      return next.handle(request);
    }
    request = request.clone({
      setHeaders: {
        Authorization: jwt
      }
    });
    return next.handle(request);
  }
}
